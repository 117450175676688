import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Auth/Login.vue'
import Store from '../store/index'

import { routes as dashboard } from './modules/dashboard.js'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/inicio',
        name: 'Home',
        component: () =>
            import ('../views/Home.vue'),
        meta: {
            requireAuth: true,
            name: 'Inicio'
        }
    },
    {
        path: '/recuperacao',
        name: 'Recover',
        component: () =>
            import ('../views/Auth/Recover.vue')
    },
    {
        path: '/novoLogin',
        name: 'New',
        component: () =>
            import ('../views/Auth/New.vue'),
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/franquia',
        name: 'franchise.home',
        component: () =>
            import ('../views/Franchise/Home/index.vue'),
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/franquia-pedidos',
        name: 'franchise.pedidos',
        component: () =>
            import ('../views/Franchise/Purchases/index.vue'),
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/franquia-promocoes',
        name: 'franchise.promocoes',
        component: () =>
            import ('../views/Franchise/Promotions/index.vue'),
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/grupo',
        name: 'groups',
        component: () =>
            import ('../views/Group/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'groups.create',
            component: () =>
                import ('../views/Group/Create.vue'),
            meta: {
                name: 'Criar Grupo'
            }
        }, {
            path: 'index',
            name: 'groups.read',
            component: () =>
                import ('../views/Group/Read.vue'),
            meta: {
                name: 'Todos os Grupos'
            }
        }, {
            path: 'index/:id',
            name: 'groups.read.one',
            component: () =>
                import ('../views/Group/Create.vue'),
            meta: {
                name: 'Grupo'
            }
            // }, {
            //     path: '/index/:id',
            //     name: 'Group.update',
            //     component: () =>
            //         import ('../views/Group/Read.vue'),
            //     meta: {
            //         name: 'Atualizar Grupo'
            //     }
        }]
    },
    {
        path: '/sub-grupo',
        name: 'subGroup',
        component: () =>
            import ('../views/SubGroup/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'subGroup.create',
            component: () =>
                import ('../views/SubGroup/Create.vue'),
            meta: {
                name: 'Criar Sub-Grupo'
            }
        }, {
            path: 'index',
            name: 'subGroup.read',
            component: () =>
                import ('../views/SubGroup/Read.vue'),
            meta: {
                name: 'Todos os Sub-Grupos'
            }
        }, {
            path: 'index/:id',
            name: 'subGroup.read.one',
            component: () =>
                import ('../views/SubGroup/Create.vue'),
            meta: {
                name: 'Sub Grupo'
            }
        }]
    },
    {
        path: '/impressao',
        name: 'print',
        component: () =>
            import ('../views/Print/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'print.create',
            component: () =>
                import ('../views/Print/Create.vue'),
            meta: {
                name: 'Criar Setor de Impressão'
            }
        }, {
            path: 'index',
            name: 'print.read',
            component: () =>
                import ('../views/Print/Read.vue'),
            meta: {
                name: 'Todos os Setores de Impressão'
            }
        }, {
            path: 'index/:id',
            name: 'print.read.one',
            component: () =>
                import ('../views/Print/Create.vue'),
            meta: {
                name: 'Setor de Impressão'
            }
        }]
    },
    {
        path: '/preferencias-grupo',
        name: 'groupPreference',
        component: () =>
            import ('../views/GroupPreference/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'groupPreference.create',
            component: () =>
                import ('../views/GroupPreference/Create.vue'),
            meta: {
                name: 'Criar Preferencias de Grupo'
            }
        }, {
            path: 'index',
            name: 'groupPreference.read',
            component: () =>
                import ('../views/GroupPreference/Read.vue'),
            meta: {
                name: 'Todos as Preferencias de Grupo'
            }
        }, {
            path: 'index/:id',
            name: 'groupPreference.read.one',
            component: () =>
                import ('../views/GroupPreference/Create.vue'),
            meta: {
                name: 'Preferencias de Grupo'
            }
        }]
    },
    {
        path: '/produto',
        name: 'products',
        component: () =>
            import ('../views/Product/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'products.create',
            component: () =>
                import ('../views/Product/Create.vue'),
            meta: {
                name: 'Criar Produtos'
            }
        }, {
            path: 'index',
            name: 'products.read',
            component: () =>
                import ('../views/Product/Read.vue'),
            meta: {
                name: 'Todos os Produtos'
            }
        }, {
            path: 'index/:id',
            name: 'products.read.one',
            component: () =>
                import ('../views/Product/Create.vue'),
            meta: {
                name: 'Produto'
            }
        }]
    },
    {
        path: '/rodizio',
        name: 'kits',
        component: () =>
            import ('../views/Kits/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'kits.create',
            component: () =>
                import ('../views/Kits/Create.vue'),
            meta: {
                name: 'Criar Rodizios'
            }
        }, {
            path: 'index',
            name: 'kits.read',
            component: () =>
                import ('../views/Kits/Read.vue'),
            meta: {
                name: 'Todos os Rodizios'
            }
        }, {
            path: 'index/:id',
            name: 'kits.read.one',
            component: () =>
                import ('../views/Kits/Create.vue'),
            meta: {
                name: 'Rodizio'
            }
        }]
    },
    {
        path: '/pessoas',
        name: 'people',
        component: () =>
            import ('../views/People/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'people.create',
            component: () =>
                import ('../views/People/Create.vue'),
            meta: {
                name: 'Criar registro de Pessoas'
            }
        }, {
            path: 'index',
            name: 'people.read',
            component: () =>
                import ('../views/People/Read.vue'),
            meta: {
                name: 'Todos os registros de Pessoas'
            }
        }, {
            path: 'index/:id',
            name: 'people.read.one',
            component: () =>
                import ('../views/People/Create.vue'),
            meta: {
                name: 'Pessoa'
            }
        }]
    },
    {
        path: '/cidade',
        name: 'cities',
        component: () =>
            import ('../views/City/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'cities.create',
            component: () =>
                import ('../views/City/Create.vue'),
            meta: {
                name: 'Criar Cidades'
            }
        }, {
            path: 'index',
            name: 'cities.read',
            component: () =>
                import ('../views/City/Read.vue'),
            meta: {
                name: 'Todas as Cidades'
            }
        }, {
            path: 'index/:id',
            name: 'cities.read.one',
            component: () =>
                import ('../views/City/Create.vue'),
            meta: {
                name: 'Cidade'
            }
        }]
    },
    {
        path: '/centro-custo',
        name: 'costsCenters',
        component: () =>
            import ('../views/CostCenter/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'costsCenters.create',
            component: () =>
                import ('../views/CostCenter/Create.vue'),
            meta: {
                name: 'Criar Centro de Custos'
            }
        }, {
            path: 'index',
            name: 'costsCenters.read',
            component: () =>
                import ('../views/CostCenter/Read.vue'),
            meta: {
                name: 'Todos os Centros de Custos'
            }
        }, {
            path: 'index/:id',
            name: 'costsCenters.read.one',
            component: () =>
                import ('../views/CostCenter/Create.vue'),
            meta: {
                name: 'Centro de Custos'
            }
        }]
    },
    {
        path: '/tipo-documento',
        name: 'docTypes',
        component: () =>
            import ('../views/DocTypes/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'docTypes.create',
            component: () =>
                import ('../views/DocTypes/Create.vue'),
            meta: {
                name: 'Criar Tipos de Documentos'
            }
        }, {
            path: 'index',
            name: 'docTypes.read',
            component: () =>
                import ('../views/DocTypes/Read.vue'),
            meta: {
                name: 'Todos os Tipos de Documentos'
            }
        }, {
            path: 'index/:id',
            name: 'docTypes.read.one',
            component: () =>
                import ('../views/DocTypes/Create.vue'),
            meta: {
                name: 'Tipo de Documento'
            }
        }]
    },
    {
        path: '/mesa',
        name: 'table',
        component: () =>
            import ('../views/Table/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'table.create',
            component: () =>
                import ('../views/Table/Create.vue'),
            meta: {
                name: 'Criar Mesa'
            }
        }, {
            path: 'index',
            name: 'table.read',
            component: () =>
                import ('../views/Table/Read.vue'),
            meta: {
                name: 'Todas as Mesas'
            }
        }, {
            path: 'index/:id',
            name: 'table.read.one',
            component: () =>
                import ('../views/Table/Create.vue'),
            meta: {
                name: 'Mesa'
            }
        }]
    },
    {
        path: '/endereco',
        name: 'address',
        component: () =>
            import ('../views/Address/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'address.create',
            component: () =>
                import ('../views/Address/Create.vue'),
            meta: {
                name: 'Criar Endereço'
            }
        }, {
            path: 'index',
            name: 'address.read',
            component: () =>
                import ('../views/Address/Read.vue'),
            meta: {
                name: 'Todos os Endereços'
            }
        }, {
            path: 'index/:id',
            name: 'address.read.one',
            component: () =>
                import ('../views/Address/Create.vue'),
            meta: {
                name: 'Endereço'
            }
        }]
    },
    {
        path: '/propaganda',
        name: 'adverts',
        component: () =>
            import ('../views/Adverts/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'adverts.create',
            component: () =>
                import ('../views/Adverts/Create.vue'),
            meta: {
                name: 'Criar Propaganda'
            }
        }, {
            path: 'index',
            name: 'adverts.read',
            component: () =>
                import ('../views/Adverts/Read.vue'),
            meta: {
                name: 'Todos as Propagandas'
            }
        }, {
            path: 'index/:id',
            name: 'adverts.read.one',
            component: () =>
                import ('../views/Adverts/Create.vue'),
            meta: {
                name: 'Propaganda'
            }
        }]
    },
    {
        path: '/tipo-usuario',
        name: 'userType',
        component: () =>
            import ('../views/UserType/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'userType.create',
            component: () =>
                import ('../views/UserType/Create.vue'),
            meta: {
                name: 'Criar Tipo de Usuario'
            }
        }, {
            path: 'index',
            name: 'userType.read',
            component: () =>
                import ('../views/UserType/Read.vue'),
            meta: {
                name: 'Todos os Tipos de Usuarios'
            }
        }, {
            path: 'index/:id',
            name: 'userType.read.one',
            component: () =>
                import ('../views/UserType/Create.vue'),
            meta: {
                name: 'Tipo de Usuario'
            }
        }]
    },
    {
        path: '/usuario',
        name: 'user',
        component: () =>
            import ('../views/User/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'user.create',
            component: () =>
                import ('../views/User/Create.vue'),
            meta: {
                name: 'Criar Usuario'
            }
        }, {
            path: 'index',
            name: 'user.read',
            component: () =>
                import ('../views/User/Read.vue'),
            meta: {
                name: 'Todos os Usuarios'
            }
        }, {
            path: 'index/:id',
            name: 'user.read.one',
            component: () =>
                import ('../views/User/Create.vue'),
            meta: {
                name: 'Usuario'
            }
        }]
    },
    {
        path: '/regra-fical',
        name: 'fiscalRules',
        component: () =>
            import ('../views/FiscalRules/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'fiscalRules.create',
            component: () =>
                import ('../views/FiscalRules/Create.vue'),
            meta: {
                name: 'Criar Regra Fiscal'
            }
        }, {
            path: 'index',
            name: 'fiscalRules.read',
            component: () =>
                import ('../views/FiscalRules/Read.vue'),
            meta: {
                name: 'Todas as Regras Fiscais'
            }
        }, {
            path: 'index/:id',
            name: 'fiscalRules.read.one',
            component: () =>
                import ('../views/FiscalRules/Create.vue'),
            meta: {
                name: 'Regra Fiscal'
            }
        }]
    },
    {
        path: '/grade',
        name: 'grid',
        component: () =>
            import ('../views/Grid/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'grid.create',
            component: () =>
                import ('../views/Grid/Create.vue'),
            meta: {
                name: 'Criar Grade'
            }
        }, {
            path: 'index',
            name: 'grid.read',
            component: () =>
                import ('../views/Grid/Read.vue'),
            meta: {
                name: 'Todas as Grades'
            }
        }, {
            path: 'index/:id',
            name: 'grid.read.one',
            component: () =>
                import ('../views/Grid/Create.vue'),
            meta: {
                name: 'Grade'
            }
        }]
    },
    {
        path: '/opcao-grade',
        name: 'gridOption',
        component: () =>
            import ('../views/GridOption/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'gridOption.create',
            component: () =>
                import ('../views/GridOption/Create.vue'),
            meta: {
                name: 'Criar Opção de Grade'
            }
        }, {
            path: 'index',
            name: 'gridOption.read',
            component: () =>
                import ('../views/GridOption/Read.vue'),
            meta: {
                name: 'Todas as Opções de  Grade'
            }
        }, {
            path: 'index/:id',
            name: 'gridOption.read.one',
            component: () =>
                import ('../views/GridOption/Create.vue'),
            meta: {
                name: 'Opções de Grade'
            }
        }]
    },
    {
        path: '/empresa',
        name: 'companies',
        component: () =>
            import ('../views/Company/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'companies.create',
            component: () =>
                import ('../views/Company/Create.vue'),
            meta: {
                name: 'Criar Empresa'
            }
        }, {
            path: 'index',
            name: 'companies.read',
            component: () =>
                import ('../views/Company/Read.vue'),
            meta: {
                name: 'Todas as Empresas'
            }
        }, {
            path: 'index/:id',
            name: 'companies.read.one',
            component: () =>
                import ('../views/Company/Create.vue'),
            meta: {
                name: 'Empresas'
            }
        }]
    }, {
        path: '/fornecedores',
        name: 'providers',
        component: () =>
            import ('../views/Provider/Index.vue'),
        meta: {
            requireAuth: true
        },
        children: [{
            path: 'novo',
            name: 'providers.create',
            component: () =>
                import ('../views/Provider/Create.vue'),
            meta: {
                name: 'Criar Empresa'
            }
        }, {
            path: 'index',
            name: 'providers.read',
            component: () =>
                import ('../views/Provider/Read.vue'),
            meta: {
                name: 'Todas as Empresas'
            }
        }, {
            path: 'index/:id',
            name: 'providers.read.one',
            component: () =>
                import ('../views/Provider/Create.vue'),
            meta: {
                name: 'Empresas'
            }
        }]
    },
    ...dashboard
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

// eslint-disable-next-line
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (Store.getters.isLogged) {
            next()
            return
        } else {
            next('/')
        }
    } else {
        next()
    }
})