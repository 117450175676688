const md5 = require('md5')
const Auth = {
    state: ({
        logged: false,
        _Auth: {

        },
        _Default: {
            user: null,
            pass: null
        }
    }),
    mutations: {
        /**
         * Seta os dados do usuarios no localStorage.
         * caso já exista valores no local storage locga o usuario
         * @param {Vuex} state 
         * @param {Object} data 
         */
        SET_INFO(state, data) {
            let item = localStorage.getItem('gestor-food')

            if (item) {
                state.logged = true
                state._Auth = JSON.parse(item)
            }else{
                localStorage.setItem('gestor-food', JSON.stringify(data))

                state.logged = true
                state._Auth = data
            }
        },

        /**
         * Apaga os dados do usuario do localstorage
         * @param {Vuex} state 
         */
        LOGOUT(state) {
            localStorage.clear()

            state.logged = false
            state._Auth = {}
        }
    },
    actions: {
        /**
         * Faz a requisicao de login de usuario, retornando uma promise
         * @param {Object} param
         * @param {Object} data 
         * @returns Promise
         */
        _LOGIN: function({state, commit}, data) {
            state._Default.user = md5(data.user + process.env.VUE_APP_SALT)
            state._Default.pass = md5(data.pass + process.env.VUE_APP_SALT)

            return new Promise((accept, reject) => {
                fetch(process.env.VUE_APP_API + '/auth/login', {
                    method: 'POST',
                    headers: new Headers({
                        'Authentication': JSON.stringify({
                            user: state._Default.user,
                            pass: state._Default.pass
                        })
                    })
                })
                .then(response => {
                    if (response.status === 200) {
                        response.json()
                            .then(res => {
                                if (res.data) {
                                    commit('SET_INFO', res.response[0])
                                    accept({
                                        data: true,
                                        response: res.response[0]
                                    })
                                }else{
                                    commit('LOGOUT')
                                    reject({
                                        data: false,
                                        message: res.message
                                    })
                                }
                            })
                    }else{
                        commit('LOGOUT')
                        reject({
                            data: false,
                            message: 'Erro ao autenticar'
                        })
                    }
                })
                .catch((error) => {
                    console.error('Error', '=>', error)
                    commit('LOGOUT')
                    reject({
                        data: false,
                        message: error.message ? error.message : 'Erro na comunicação com o servidor'
                    })
                })
            })
        },

        /**
         * Funcao que faz a requisicao para apagar o token da sessao
         * @param {Object} context 
         */
        _LOGOUT: function(context) {
            fetch(process.env.VUE_APP_API + '/auth/logout', {
                method: 'POST',
                headers: new Headers({
                    'Authentication': context.state._Auth.token
                })
            }).finally(() => {
                context.commit('LOGOUT')
            })
        },

        /**
         * Funcao para autenticacao passiva de usuarios.
         * Deve ser chamada quando houver erros na requisicao com status 401
         * @returns Promisse
         */
        _AUTH: function({commit, state}){
            return new Promise((accept, reject) => {
                fetch(process.env.VUE_APP_API + '/auth/login', {
                    method: 'POST',
                    headers: new Headers({
                        'Authentication': JSON.stringify({
                            user: state._Default.user,
                            pass: state._Default.pass
                        })
                    })
                })
                    .then(response => {
                        if (response.status === 200) {
                            response.json()
                                .then(res => {
                                    if (res.data) {
                                        commit('SET_INFO', res.response[0])
                                        accept({
                                            data: true
                                        })
                                    }else{
                                        commit('LOGOUT')
                                        reject({
                                            data: false,
                                            message: res.message
                                        })
                                    }
                                })
                        }else{
                            commit('LOGOUT')
                            reject({
                                data: false,
                                message: 'Erro ao autenticar'
                            })
                        }
                    })
                    .catch(() => {
                        commit('LOGOUT')
                        reject({
                            data: false,
                            message: 'Erro ao autenticar'
                        })
                    })

            })
        }
    },
    getters: {
        /**
         * Retorna o status do usuario
         * @param {*} state 
         * @returns 
         */
        isLogged: state => {
            return state.logged
        },

        /**
         * Retorna o token do usuario
         * @param {*} state 
         * @returns 
         */
        token: state => {
            return state._Auth.token
        },

        /**
         * Retorna os dados da empresa a qual o usuario pertence
         * @param {*} state 
         * @returns 
         */
        companyName: state => {
            return state._Auth.empresa
        },

        /**
         * Retorna o nome do usuario
         * @param {*} state 
         * @returns 
         */
        userName: state => {
            return state._Auth.nome
        },

        /**
         * Retorna o tipo de usuario
         * @param {*} state 
         * @returns 
         */
        userSuper: state => {
            return state._Auth.super
        },

        userPermission: state => permission => {
            return state._Auth[permission]
        }
    }
};

export default Auth