<template>
    <v-list
        flat
        light
    >
        <v-list-item>
            <v-text-field
                v-model="search"
                label="Buscar menu"
                hide-details="auto"
            ></v-text-field>
        </v-list-item>

		<v-list-item v-if="userSuper === 'S'" >
			<v-list-item-title
				@click="clearFranchise"
			>
				<i class="fas fa-exchange-alt"></i>Mudar franquia
			</v-list-item-title>
		</v-list-item>

        <template v-for="(item, id) in menuComputed" >
            <v-list-item 
                v-if="item.childrens === null"
                :key="`menu-item-${id}`" 
                :to="item.to"
            >
                <i :class="item.icon"></i>{{item.label}}
            </v-list-item>
            <v-subheader
                v-else-if="item.separator"
                :key="`menu-separator-${id}`" 
            >
                {{item.label}}
            </v-subheader>

            <v-list-group v-if="item.to === null" :key="`menu-group-${id}`" >
                <template v-slot:activator>
                    <v-list-item-title>
                        <i :class="item.icon"></i>{{item.label}}
                    </v-list-item-title>
                </template>

                <template>
                    <v-list-item 
                        v-for="(itens, key) in item.childrens" 
                        :to="itens.to" 
                        :key="`menu-item-${key}`"
                    >
                        <v-list-item-title>
                            <i :class="itens.icon"></i>{{itens.label}}
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-list-group>
        </template>
    </v-list>
</template>

<script>
const items = [
				{
					to: {name: 'Home'},
					icon: 'fas fa-home',
					label: 'Dashboard',
					allowed: null,
					childrens: null
                },
				{
					to: {name: 'cashregister'},
					icon: 'fas fa-cash-register',
					label: 'Faturamento / Caixa',
					allowed: null,
					childrens: null
                },
				{
					to: {name: 'tablemap'},
					icon: 'fas fa-border-all',
					label: 'Mapa de Mesas',
					allowed: null,
					childrens: null
                },
				{
					to: {name: 'inventory'},
					icon: 'fas fa-boxes',
					label: 'Estoque',
					allowed: null,
					childrens: null
                },
                {
                    separator: true,
                    label: 'Cadastros'
                },
				// {
				// 	to: {name: 'companies.read'},
				// 	icon: 'far fa-building',
				// 	label: 'Empresa',
				// 	allowed: null,
				// 	childrens: null
                // },
				// {
				// 	to: {name: 'providers.read'},
				// 	icon: 'fas fa-home',
				// 	label: 'Fornecedores',
				// 	allowed: null,
				// 	childrens: null
                // },
                // {
				// 	to: null,
				// 	icon: 'fas fa-male',
				// 	label: 'Pessoas',
				// 	allowed: [],
				// 	childrens: [
						// {
						// 	to: {name: 'people.read'},
						// 	icon: 'fas fa-user-friends',
						// 	label: 'Pessoas',
                        //     allowed: null,
                        //     childrens: null
						// },
						// {
						// 	to: {name: 'user.read'},
						// 	icon: 'fas fa-user',
						// 	label: 'Usuários',
                        //     allowed: null,
                        //     childrens: null
						// },
						// {
						// 	to: {name: 'userType.read'},
						// 	icon: 'fas fa-user-shield',
						// 	label: 'Tipo de usuários',
                        //     allowed: null,
                        //     childrens: null
						// },
				// 	]
				// },
				// {
					// to: null,
					// icon: 'fas fa-layer-group',
					// label: 'Produtos',
					// allowed: [],
					// childrens: [
						// {
						// 	to: {name: 'products.read'},
						// 	icon: 'fas fa-align-justify',
						// 	label: 'Produtos / Cardapio',
                        //     allowed: null,
                        //     childrens: null
						// },
						// {
						// 	to: {name: 'grid.read'},
						// 	icon: 'fas fa-object-group',
						// 	label: 'Grades',
                        //     allowed: null,
                        //     childrens: null
						// },
						// {
						// 	to: {name: 'gridOption.read'},
						// 	icon: 'fas fa-object-ungroup',
						// 	label: 'Opções de grade',
                        //     allowed: null,
						// },
						// {
						// 	to: {name: 'fiscalRules.read'},
						// 	icon: 'fas fa-pencil-ruler',
						// 	label: 'Regras fiscais',
                        //     childrens: null	
						// },
				// 	]
				// },
				// {
				// 	to: null,
				// 	icon: 'fas fa-layer-group',
				// 	label: 'Grupos de produtos',
				// 	allowed: [],
				// 	childrens: [
						// {
						// 	to: {name: 'print.read'},
						// 	icon: 'fas fa-print',
						// 	label: 'Setores de impressão',
                        //     allowed: null,
                        //     childrens: null
						// },
						// {
						// 	to: {name: 'groups.read'},
						// 	icon: 'fas fa-object-group',
						// 	label: 'Grupos',
                        //     allowed: null,
                        //     childrens: null
						// },
						// {
						// 	to: {name: 'subGroup.read'},
						// 	icon: 'fas fa-object-group',
						// 	label: 'Sub Grupos',
                        //     allowed: null,
                        //     childrens: null
						// },
						// {
						// 	to: {name: 'groupPreference.read'},
						// 	icon: 'fas fa-object-ungroup',
						// 	label: 'Preferências de grupo',
                        //     allowed: null,
                        //     childrens: null
						// },
						// {
						// 	to: {name: 'kits.read'},
						// 	icon: 'fas fa-adjust',
						// 	label: 'Rodizios',
                        //     allowed: null,
                        //     childrens: null
						// },
						// {
						// 	to: {name: 'table.read'},
						// 	icon: 'fas fa-table',
						// 	label: 'Mesas',
                        //     allowed: null,
                        //     childrens: null
						// },
				// 	]
				// },
				// {
				// 	to: null,
				// 	icon: 'fas fa-envelope-square',
				// 	label: 'Endereços',
				// 	allowed: [],
				// 	childrens: [
						// {
						// 	to: {name: 'cities.read'},
						// 	icon: 'fas fa-city',
						// 	label: 'Cidades',
                        //     allowed: null,
                        //     childrens: null
						// },
						// {
						// 	to: {name: 'address.read'},
						// 	icon: 'fas fa-map-marker-alt',
						// 	label: 'Endereços',
                        //     allowed: null,
                        //     childrens: null
						// },
				// 	]
				// },
				// {
				// 	to: null,
				// 	icon: 'fas fa-coins',
				// 	label: 'Financeiro',
				// 	allowed: [],
				// 	childrens: [
						// {
						// 	to: {name: 'costsCenters.read'},
						// 	icon: 'fas fa-folder-open',
						// 	label: 'Centros de custo',
                        //     allowed: null,
                        //     childrens: null
						// },
						// {
						// 	to: {name: 'docTypes.read'},
						// 	icon: 'fas fa-file-word',
						// 	label: 'Tipos de documento',
                        //     allowed: null,
                        //     childrens: null
						// },
				// 	]
				// },
				// {
				// 	to: {name: 'adverts.read'},
				// 	icon: 'fas fa-bullhorn',
				// 	label: 'Propagandas',
                //     allowed: null,
                //     childrens: null
				// },
                // {
                //     separator: true,
                //     label: 'Em desenvolvimento/ Franquias'
                // },
				// {
				// 	to: {name: 'franchise.home'},
				// 	icon: 'fas fa-home',
				// 	label: 'Visão básica',
				// 	allowed: null,
				// 	childrens: null
                // },
				// {
				// 	to: {name: 'franchise.pedidos'},
				// 	icon: 'fas fa-home',
				// 	label: 'Pedidos',
				// 	allowed: null,
				// 	childrens: null
                // },
				// {
				// 	to: {name: 'franchise.promocoes'},
				// 	icon: 'fas fa-home',
				// 	label: 'Promoções',
				// 	allowed: null,
				// 	childrens: null
                // },
            ]


import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'UiMenuPage',
    data(){
        return {
            search: '',
            menuItems: []
        }
    },
	mounted(){
		setTimeout(() => {
			this.menuItems = [ ...items ]
		}, 200)
	},
    computed: {
        ...mapGetters({
            userSuper: 'userSuper'
        }),
        menuComputed(){
            let menu = []

            this.menuItems.map(e => {
                if (e.allowed) {
                    if (this.permissions(e.allowed) === 'S') {
                        if (this.search) {
                            let label = e.label.toLowerCase()
                            if (label.includes(this.search.toLowerCase())) menu.push(e)
                        }else{
                            menu.push(e)
                        }
                    }
                }else{
                    if (this.search) {
                        if (e.label.includes(this.search)) menu.push(e)
                    }else{
                        menu.push(e)
                    }
                }
            })

            return menu
        },
    },

	methods: {
		...mapMutations({
			'clear': 'CLEAR_FRANCHISE'
		}),

		clearFranchise() {
			this.clear()
		}
	}
}
</script>

<style scoped>
	i.fas{
		display: inline-block;
		width: 35px;
		text-align: center;
	}
</style>