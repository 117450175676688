import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VuePageTransition from 'vue-page-transition'
import VueMask from 'v-mask'
import VueMoney from 'vuetify-money'

Vue.use(VueMoney)
Vue.use(VueMask)
Vue.use(VuePageTransition)

const moment = require('moment')
require('moment/locale/pt-br')

moment.suppressDeprecationWarnings = true
Vue.use(require('vue-moment'), {
  moment,
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
