<template>
    <v-navigation-drawer 
        permanent
        floating
        app
        right
        width="360"

        v-if="layout.showSidebar"
    >
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="text-h6">
                    Histórico de processamento
                </v-list-item-title>
                <v-list-item-subtitle>
                    Nome da Empresa
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-btn @click="layout.showSidebar = false" >
            Fechar
        </v-btn>

        <v-overlay 
            :value="layout.overlay"
            absolute
        >
            <v-progress-circular
                size="100"
                color="indigo darken-1"
            >
            </v-progress-circular>
        </v-overlay>
    </v-navigation-drawer>
</template>

<script>
export default {
    data(){
        return {
			layout: {
				showSidebar: false,
                overlay: false,
                search: null
			},
        }
    },
	beforeMount(){
		this.$root.$on('franchise-history-open-sidebar', () => {
            this.layout.overlay = true
            this.layout.showSidebar = true

            setTimeout(() => {
                this.layout.overlay = false
            }, 1000)
		})

		this.$root.$on('franchise-history-close-sidebar', () => {
			this.layout.showSidebar = false
		})
	},

    computed: {

    },

    methods: {

    }
}
</script>