<template>
	<v-app>
		<v-app-bar
			app
			color="indigo darken-2"
			dark
		>
			<v-toolbar-title>Rede de Lojas Fatimas <span v-if="franchiseName" >{{' / ' + franchiseName}}</span> </v-toolbar-title>

			<v-btn
				outlined
				small
				color="white"
				class="mx-1"

				v-if="isLogged"
				@click.stop="drawer = !drawer"
			>
				<i class="fas fa-bars"></i>
				<span>Menu</span>
			</v-btn>

			<v-spacer></v-spacer>

			<v-btn
				v-if="isLogged && userSuper === 'S'"
				@click="openFranchise"

				outlined
				small
				color="white"
				class="mx-1"
			>
				Mudar Empresa
				<i class="fas fa-exchange-alt"></i>
			</v-btn>
			<v-dialog
				v-if="isLogged && ['Home'].includes($route.name)"
				ref="dialog"
				v-model="date.show"
				:return-value.sync="date.value"
				persistent
				width="290px"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-if="isLogged"
						v-bind="attrs"

						outlined
						small
						color="white"
						class="mx-1"
						v-on="on"
					>
						{{$moment(date.value, 'YYYY-MM-DD').format('DD/MM/YYYY') || 'Sem data'}}
						<i class="far fa-calendar-alt"></i>
					</v-btn>
				</template>
				<v-date-picker
					v-model="date.value"
					scrollable
				>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="primary"
						@click="date.show = false"
					>
						Cancelar
					</v-btn>
					<v-btn
						text
						color="primary"
						@click="changeData(date.value)"
					>
						Alterar
					</v-btn>
				</v-date-picker>
			</v-dialog>
			
			<v-spacer></v-spacer>

			<div v-if="isLogged" >
				<v-menu
					offset-y
					rounded
				>
					<template v-slot:activator="{ attrs, on }">
						<v-btn
							outlined
							color="white"
							class="mx-1"
							fab
							small

							v-bind="attrs"
							v-on="on"
						>
							<i class="fas fa-2x fa-user-tie"></i>
						</v-btn>
					</template>

					<v-list>
						<v-list-item>
							<v-list-item-title>{{userName}}</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title @click.prevent="logout()" >Sair</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
		</v-app-bar>

		<v-navigation-drawer
			v-model="drawer"
			absolute
			clipped
			temporary
			width="350"
		> 
			<v-card
				class="indigo white--text mx-auto rounded-0"
			>
				<v-card-title>
					{{company}}
				</v-card-title>

				<v-card-text class="white--text" >
					<strong>{{userName}}</strong>
				</v-card-text>
			</v-card>
			<ui-menu-page :drawer="drawer" />
			<template v-slot:append>
				<div class="pa-2 indigo darken-2 text-center white--text">
					<small>
						Jointech Tecnologia em Sistemas
					</small>
				</div>
			</template>

		</v-navigation-drawer>

		<v-main style="100%" >
			<router-view/>

			<fsidebar v-if="isLogged" />
		</v-main>

		<fhistory />

		<v-dialog
			v-model="dialog.show"
			hide-overlay
			persistent
			width="400"
		>
			<v-card
				color="indigo darken-2"
				dark
			>
				<v-card-title>
					Existe uma empresa pré selecionada
				</v-card-title>

				<v-card-text>
					A empresa {{dialog.franchise.franchise}} esta pré selecionada.
					Deseja carregar os dados da mesma?
				</v-card-text>

				<v-card-actions>
					<v-btn @click="dialog.show = false" >
						Cancelar
					</v-btn>
					<v-divider />
					<v-btn @click="selectFranchise" >
						Aceitar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <v-snackbar
            v-model="snackbar.show"
            :timeout="3000"
            :color="snackbar.type"
        >
            {{snackbar.text}}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar.show = false"
                >
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
	</v-app>
</template>

<script>
import { mapMutations, mapGetters } from "vuex"
import { fsidebar, fhistory, uiMenuPage } from '@/components/ui'

export default {
	name: "App",

	components: {
		fsidebar,
		fhistory,
		uiMenuPage
	},

	data() {
		return {
			drawer: false,
			group: null,
			dialog: {
				show: false,
				franchise: {
					franchise: null,
					id: null,
					date: null
				}
			},
			snackbar: {
				show: false,
				type: 'indigo',
				text: ''
			},
			date: {
				show: false,
				value: null
			}
		}
	},

	computed: {
		...mapGetters({
			company: 'companyName',
			isLogged: 'isLogged',
			userName: 'userName',
			userSuper: 'userSuper',
			franchiseName: 'franchiseName',
			franchiseDate: 'franchiseDate'
		})
	},

	watch: {
		group () {
			this.drawer = false
		}
    },

	methods: {
		...mapMutations({
			setInfo: 'SET_INFO',
			setFranchise: 'SET_FRANCHISE',
			setFranchiseDate: 'SET_FRANCHISE_DATE'
		}),

		logout: function(){
			this.$store.dispatch('_CLEAR_FRANCHISE')
			this.$store.dispatch('_LOGOUT')

			this.$router.push('/')
		},

		onLoading: function(){
			const localStorageItem = window.localStorage.getItem('gestor-food')
			if (localStorageItem) {
				this.setInfo()

				this.$router.push({
					name: 'Home'
				})
			}

			const franchiseStorage = JSON.parse(window.localStorage.getItem('gestor-food-franchise'))
			if (franchiseStorage) {
				this.dialog.franchise = franchiseStorage
				this.dialog.show = true			
			}

		},

		openFranchise(){
			this.$root.$emit('franchise-open-sidebar')
		},

		selectFranchise(){
			this.setFranchise(this.dialog.franchise)
			this.$root.$emit('reload-all-franchise-graphs', this.dialog.franchise.id)
			this.dialog.show = false
		},

		changeData(value) {
			this.date.show = false
			this.date.value = value

			this.setFranchiseDate(value)

			this.$nextTick(() => {
				this.$root.$emit('reload-all-franchise-graphs')
			})
		}
	},

	beforeCreate(){
		this.$nextTick(() => {
			this.onLoading()
		})
	},

	mounted() {
		this.$root.$on('show-exception-alert', (data) => {
			this.snackbar.text = data
			this.snackbar.show = true
		})

		this.$root.$on('reload-all-franchise-graphs', () => {
			this.date.value = this.franchiseDate
		})
	},

	beforeDestroy() {
		this.$root.$off('show-exception-alert')
		this.$root.$off('reload-all-franchise-graphs')
	}
}
</script>