<template>
    <div>
        <v-snackbar
            v-model="alert.show"
            :timeout="3000"
            :color="alert.type"
        >
            {{alert.text}}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="alert.show = false"
                >
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
        <v-dialog
            v-model="dialog"
            max-width="350px"
        >
            <v-card>
                <v-card-title>
                    Excluir registro?
                </v-card-title>
                <v-card-actions>
                    <v-btn
                        @click.prevent="dialog = false"
                    >
                        Sair
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        @click.prevent="deleteItem"
                    >
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            class="elevation-1"
        >
            <template #top >
                <v-toolbar flat >
                    <v-toolbar-title>
                        {{pTitle}}
                    </v-toolbar-title>

                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                    <v-spacer></v-spacer>

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        hide-details
                    ></v-text-field>

                    <v-spacer></v-spacer>

                    <v-btn
                        color="indigo darken-1"
                        dark
                        fab
                        small
                        :to="{name: pCreateLink}"
                    >
                        <v-icon>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-for="(h, index) in headers" v-slot:[`item.${h.value}`]="{ item }">
                <div :key="index" v-if="formateDateRange(h.value)" >
                    {{formatDate(item[h.value])}}
                </div>
                <div :key="index" v-else-if="formateDocumentRange(h.value)" >
                    {{formatDocument(item[h.value])}}
                </div>
                <div :key="index" v-else-if="formateContactRange(h.value)" >
                    {{formatContact(item[h.value])}}
                </div>
                <div :key="index" v-else >
                    {{verifyItem(item[h.value])}}
                </div>
            </template>
            <template v-slot:item.actions="{ item }" >
                <v-btn
                    class="mr-2"
                    color="indigo darken-1"
                    dark
                    fab
                    small

                    :to="{name: pReadLink, params: {id: item.id}}"
                >
                    <v-icon
                    >
                        mdi-pencil
                    </v-icon>
                </v-btn>
                <v-btn
                    class="mr-2"
                    color="pink darken-1"
                    dark
                    fab
                    small

                    @click="openDeleteItemDialog(item.id)"
                >
                    <v-icon
                    >
                        mdi-delete
                    </v-icon>
                </v-btn>
            </template>

            <v-overlay :value="overlay">
                <v-progress-circular
                    size="100"
                    color="indigo darken-1"
                >
                </v-progress-circular>
            </v-overlay>
        </v-data-table>
    </div>
</template>

<script>
    export default {
        name: 'uiDataTable',
        props: {
            pHeaders: {
                type: Array,
                require: true
            },

            pTitle: {
                type: String,
                require: true
            },

            pReadLink: {
                type: String,
                require: true
            },

            pCreateLink: {
                type: String,
                require: true
            },

            pName: {
                type: String,
                require: true
            }
        },
        data(){
            return {
                search: '',
                alert: {
                    show: false,
                    type: 'indigo',
                    text: ''
                },
                selected: null,
                overlay: false,
                dialog: false,
                items: []
            }
        },

        computed: {
            headers(){
                return this.pHeaders
            },
        },

        mounted(){
            this.getData()
        },

        methods: {
            formateDateRange(name){
                return [
                    'criado_em',
                    'alterado_em'
                ].includes(name)
            },
            
            formateDocumentRange(name){
                return [
                    'cpf',
                    'cnpj'
                ].includes(name)
            },

            formateContactRange(name){
                return [
                    'telefone',
                    'celular',
                    'contato'
                ].includes(name)
            },

            formatDate(date){
                return this.$moment.utc(date).isValid() ? this.$moment.utc(date).format('DD/MM/YYYY') : ' - '
            },

            formatDocument(document){
                return document
            },

            formatContact(contact){
                return contact
            },

            openDeleteItemDialog(id){
                console.log('Item', '=>', id)
                this.selected = id
                this.dialog = true
            },

            deleteItem(){
                fetch(`${process.env.VUE_APP_API}${this.pName}/delete/${this.selected}`, {
                    method: 'DELETE',
                    headers: new Headers({
                        'Authentication': this.$store.getters.token
                    })
                })
                    .then(response => (response.json()))
                    .then(resJSON => {
                        if(resJSON.data){
                            this.getData()
                        }else{
                            this.$set(this.alert, 'show', true)
                            this.$set(this.alert, 'text', resJSON.message)
                        }
                    })
                    .catch(error => {
                        this.$set(this.alert, 'show', true)
                        this.$set(this.alert, 'text', error.message)
                    })
                    .finally(() => {
                        this.dialog = false
                    })
            },

            getData(){
                fetch(`${process.env.VUE_APP_API}${this.pName}/read`, {
                    method: 'GET',
                    headers: new Headers({
                        'Authentication': this.$store.getters.token
                    })
                })
                    .then(response => (response.json()))
                    .then(resJSON => {
                        if(resJSON.data){
                            this.items = resJSON.response[0].listhelper
                        }
                    })
                    .catch(error => {
                        this.alert = {
                            text: error.message,
                            show: true,
                            type: 'red darken-1'
                        }
                    })
                    .finally(() => {
                        this.overlay = false
                    })
            },

            verifyItem(item){
                let res = item

                if (!item) res = '-'
                if (item == 's' || item == 'S') res = 'Sim' 
                if (item == 'n' || item == 'N') res = 'Não'

                return res
            }
        }
    }
</script>

<style>

</style>