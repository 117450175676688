const Franchise = {
    state: ({
        franchise: '',
        id: 0,
        date: null
    }),
    mutations: {
        SET_FRANCHISE(state, data) {
            state.franchise = data.franchise
            state.id = data.id
            state.date = data.date
        },
        CLEAR_FRANCHISE(state) {
            state.franchise = ''
            state.id = 0
            state.date = null
        },
        SET_FRANCHISE_DATE(state, date) {
            state.date = date
        }
    },
    actions: {
        _SET_FRANCHISE: function(context, data){
            context.commit('SET_FRANCHISE', data)
            localStorage.setItem('gestor-food-franchise', JSON.stringify(data))
        },
        _CLEAR_FRANCHISE: function(context) {
            context.commit('CLEAR_FRANCHISE')
        },
        _SET_FRANCHISE_DATE: function(context, date) {
            context.commit('SET_FRANCHISE_DATE', date)
        }
    },
    getters: {
        franchiseName: state => {
            return state.franchise
        },
        franchiseId: state => {
            return state.id
        },
        franchiseDate: state => {
            return state.date
        }
    }
}

export default Franchise