import CashRegister from '@/views/CashRegister/index.vue'
import TableMap from '@/views/TableMap/index.vue'
import Inventory from '@/views/inventory/index.vue'

var routes = [
    {
        path: '/caixa',
        name: 'cashregister',
        component: CashRegister,
        meta: {
            requireAuth: true,
            name: 'Inicio'
        }
    }, {
        path: '/mapa-de-mesas',
        name: 'tablemap',
        component: TableMap,
        meta: {
            requireAuth: true,
            name: 'Inicio'
        }
    }, {
        path: '/estoque-detalhado',
        name: 'inventory',
        component: Inventory,
        meta: {
            requireAuth: true,
            name: 'Inicio'
        }
    }
]

export { routes }