<template>
    <v-row class="mx-2 my-2" >
        <v-col
            cols="12"
        >
            <v-card>
                <v-card-title class="justify-space-between" >
                    <h3>
                        Mapa de mesas
                    </h3>

                    <h5>
                        {{`${data.values.length || 0} - Mesas ativas`}}
                    </h5>
                </v-card-title>
            </v-card>
        </v-col>

        <template v-if="data.values.length" >
            <v-col
                cols="12"
                md="12"
            >
                <v-row>
                    <v-col
                        cols="6"
                        md="4"

                        v-for="table in data.values"
                        :key="`${table.codigo}-table-map-item`"
                    >
                        <v-card class="table-card" >
                            <v-card-title class="justify-space-between" >
                                <h2>{{table.codigo}}</h2>

                                <i class="fas fa-2x fa-users" v-if="table.status === 'a'" size="2x"  ></i>
                                <i class="fas fa-2x fa-user-slash" v-else-if="table.status === 't' " size="2x"  ></i>
                                <i class="fas fa-2x fa-user-clock" v-else-if="table.status === 'f' " size="2x"  ></i>
                                <i class="fas fa-2x fa-user-times" v-else-if="table.status === 'v' " size="2x"  ></i>
                                <i class="fas fa-2x fa-users" v-else size="2x"  ></i>
                            </v-card-title>
                            <v-card-text>
                                <p>
                                    <i class="fas fa-stopwatch"></i> Minutos: {{table.minutos || 0}}
                                </p>
                                <p>
                                    <i class="fas fa-cash-register"></i> SubTotal: {{formater(table.subtotal) || 0}}
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>

            <!-- <v-col
                cols="12"
                md="4"
            >
                <v-card>
                    <v-card-title>
                        <h5>Detalhes da mesa</h5>
                    </v-card-title>
                </v-card>
            </v-col> -->
        </template>
        <template v-else >
            <v-container class="text-center" >
                <h6>Não existem mesas ativas</h6>
            </v-container>
        </template>

        <v-overlay 
            :value="overlay"
            absolute
        >
            <v-progress-circular
                size="100"
                color="indigo darken-1"
            >
            </v-progress-circular>
        </v-overlay>
    </v-row>
</template>

<script>
import helper from "@/mixins/helper"
import numeric from "@/mixins/numeric"
import { mapGetters } from 'vuex'

export default {
    name: 'vwTableMap',

    mixins: [helper, numeric],

    data() {
        return {
            overlay: false,
            data: {
                values: []
            }
        }
    },

    computed: {
        ...mapGetters({
            userToken: 'token',
            franchiseID: 'franchiseId'
        })
    },

    methods: {
        async getTableMap() {
            this.overlay = true
            // const date = this.$moment(this.cashRegisterDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
            const response = await this.getData(`${process.env.VUE_APP_API}/graphs/generics/tablemap`, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.userToken,
                    'Franchise': this.franchiseID,
                    'FranchiseDate': null
                })
            })

            if (response.data) {
                this.$set(this.data, 'values', response.response)
            }else {
                this.$root.$on('show-exception-alert', response.message)
            }

            this.overlay = false
        }
    },

    mounted() {
        this.getTableMap()

        this.$root.$on('reload-all-franchise-graphs', () => {
            this.getTableMap()
        })
    }
}
</script>

<style scoped>
    i.fas{
        display: inline-block;
        width: 35px;
        font-size: 20px;
        text-align: center;
    }

    .table-card{
        cursor: pointer;
    }
</style>