<template>
    <v-card>
        <v-card-title>
            {{company.nome || '-'}}

            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-spacer></v-spacer>

            {{company.email || '-'}}
        </v-card-title>
        <v-card-text>
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-avatar
                    class="ma-3"
                    size="125"
                    tile
                >
                    <v-img :src="company.logo" ></v-img>
                </v-avatar>
                <div class="text-left" >
                    <p>
                        <small>Razão social: </small> <strong>{{company.razao_social || '-'}}</strong>
                    </p>
                    <p>
                        <small>Telefone: </small> <strong>{{company.telefone || '-'}}</strong>
                    </p>
                    <p>
                        <small>Cnpj: </small> <strong>{{company.cnpj || '-'}}</strong>
                    </p>
                    <p>
                        <small>Inscrição: </small> <strong>{{company.inscricao || '-'}}</strong>
                    </p>
                    <p>
                        <small>Cadastrado em: </small> <strong>{{company.criado_em || '-'}}</strong>
                    </p>
                    <p>
                        <small>Última atualização: </small> <strong>{{company.atualizado_em || '-'}}</strong>
                    </p>
                </div>
            </div>
        </v-card-text>

        <v-overlay 
            :value="layout.overlay"
            absolute
        >
            <v-progress-circular
                size="100"
                color="indigo darken-1"
            >
            </v-progress-circular>
        </v-overlay>
    </v-card>
</template>
<script>
    export default {
        data(){
            return {
                layout: {
                    overlay: false
                },
                company: {
                    nome: null
                }
            }
        },

        mounted(){
            this.$root.$on('reload-all-franchise-graphs', (data) => {
                this.layout.overlay = true

                fetch(`${process.env.VUE_APP_API}/companies/read/${data}`, {
                    method: 'GET',
                    headers: new Headers({
                        'Authentication': this.$store.getters.token
                    })
                })
                    .then(response => (response.json()))
                    .then(resJSON => {
                        if(resJSON.data){
                            this.company = resJSON.response[0].listhelper[0]
                        }
                    })
                    .catch(error => {
                        console.error('Exception', '=>', error)
                        this.alert = {
                            text: 'Erro interno ao listar a empresa!',
                            show: true,
                            type: 'red darken-1'
                        }
                    })
                    .finally(() => {
                        this.layout.overlay = false
                    })
            })
        }
    }
</script>