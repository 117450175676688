<template>
    <v-navigation-drawer 
        v-if="layout.showSidebar"
        absolute
        permanent
        right
    >
        <v-divider></v-divider>

        <v-list-item>
            <v-text-field
                v-model="layout.search"
                append-icon="mdi-magnify"
                label="Buscar"
                hide-details
            ></v-text-field>
        </v-list-item>

        <v-list
            dense
            nav
        >
            <v-list-item-group
                v-model="selectedItem"
                color="primary"
            >
                <v-list-item 
                    v-for="company in filteredCompanies"
                    :key="'company-' + company.id"
                    @click="franchiseEvent(company)"
                    two-line
                >
                    <v-list-item-content>
                        <v-list-item-title>
                            {{company.empresaNome || 'Sem nome cadastrado'}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{company.donoNome || 'Sem responsável'}} - 
                            {{(company.empresaTelefone || company.donoTelefone) || ' - '}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapGetters } from "vuex"
import helper from "@/mixins/helper"
export default {
    mixins: [helper],
    data(){
        return {
			layout: {
				showSidebar: false,
                overlay: false,
                search: null
			},

            companies: [],
            selected: {
                name: null,
                address: null,
                id: null
            },
            selectedItem: null,
        }
    },

	async mounted(){
		this.$root.$on('franchise-open-sidebar', () => {
            this.layout.showSidebar = !this.layout.showSidebar
            this.setSelected(this.franchiseID)
		})

		this.$root.$on('franchise-close-sidebar', () => {
			this.layout.showSidebar = false
		})

        const response = await this.getData(process.env.VUE_APP_API + '/info/companies', {
            method: 'GET',
            headers: new Headers({
                'Authentication': this.$store.getters.token
            })                
        })

        if (response.data)
            this.companies = response.response
        else
            this.alert = {
                text: response.message,
                show: true,
                type: 'red darken-1'
            }
	},

    beforeDestroy() {
        this.$root.$off('franchise-open-sidebar')
        this.$root.$off('franchise-close-sidebar')
    },

    computed: {
        filteredCompanies(){
            if (!this.layout.search || this.layout.search.length < 3) return this.companies
            
            let companies = []

            this.companies.forEach(e => {
                if (e.nome.toLowerCase().includes(this.layout.search.toLowerCase())) {
                    companies.push(e)
                }
            })

            return companies
        },
        ...mapGetters({
            franchiseID: 'franchiseId'
        })
    },

    methods: {
		...mapMutations({
			setFranchise: 'SET_FRANCHISE'
		}),
        franchiseEvent(franchise){
            this.setFranchise({
                franchise: franchise.empresaNome,
                id: franchise.id,
                date: franchise.dataAtual
            })
            this.setSelected(franchise.id)
            this.$root.$emit('reload-all-franchise-graphs', franchise.id)
            this.layout.showSidebar = false
        },
        setSelected(id) {
            this.selectedItem = this.filteredCompanies.findIndex(x => x.id === id)
        }
    }
}
</script>