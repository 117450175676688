<template>
    <v-container>
        <v-card>
            <v-card-title> 
                Estoque

                <v-spacer></v-spacer>

                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    hide-details
                ></v-text-field>
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="data"
                    :search="search"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :footer-props="{
                        'items-per-page-text':'Itens por página', 
                        'items-per-page-all-text': 'Todos',
                        'items-per-page-options': [25, 50, 100, -1]
                    }"
                    no-data-text="Nenhum item encontrado"
                    no-results-text="Nenhum item encontrado"
                    class="elevation-1"
                >
                    <template #footer.page-text="props">
                        {{props.pageStart}}-{{props.pageStop}} de {{props.itemsLength}}
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import helper from "@/mixins/helper"
import numeric from "@/mixins/numeric"
import { mapGetters } from 'vuex'

export default {
    name: 'vwInvenory',

    mixins: [helper, numeric],

    data() {
        return {
            overlay: false,
            data: [],
            search: '',
            sortBy: 'produto',
            sortDesc: false,
            headers: [
                { value: 'produto', text: 'ID' }, 
                { value: 'descricao', text: 'Produto' }, 
                { value: 'grupo', text: 'Grupo' }, 
                { value: 'estoque', text: 'Estoque' }, 
                { value: 'precoVenda', text: 'Preço de venda' }, 
                { value: 'precoCompra', text: 'Preço de compra' }
            ]
        }
    },

    computed: {
        ...mapGetters({
            userToken: 'token',
            franchiseID: 'franchiseId'
        })
    },

    methods: {
        async getInventory() {
            this.overlay = true

            const response = await this.getData(`${process.env.VUE_APP_API}/graphs/generics/inventory`, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.userToken,
                    'Franchise': this.franchiseID,
                    'FranchiseDate': null
                })
            })

            if (response.data) {
                this.data = response.response
            }else {
                this.$root.$on('show-exception-alert', response.message)
            }

            this.overlay = false
        }
    },

    mounted() {
        this.getInventory()
    }
}
</script>

<style>

</style>