const colors = [
    '#EF5350',
    '#B71C1C',
    '#4A148C',
    '#304FFE',
    '#006064',
    '#64B5F6',
    '#00E676',
    '#00B8D4',
    '#01579B',
    '#64DD17',
    '#43A047',
    '#00BFA5',
    '#6D4C41',
    '#546E7A',
    '#FFC400',
    '#F06292',
    '#F50057',
    '#B2DFDB',
    '#EEFF41',
    '#FF6E40',
]

export default {
    methods: {
        async getData(route, params) {
            const response = await fetch(route, params)
            const resJSON = await response.json()

            if (response.status == 401) {
                // Busca um novo token
                return {
                    data: false,
                    message: 'Token não autorizado.'
                }
            }

            if (response.status == 404) {
                return {
                    data: false,
                    message: 'Rota não encontrada.'
                }
            }

            if (response.status == 500) {
                if (resJSON.data && resJSON.data === false) {
                    return resJSON
                }
            }

            if (response.status == 200) {
                return resJSON
            }
        },

        getRandomIntInclusive(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },

        getColors(value) {
            let arrDif = colors.filter(e => {
                if (!value.includes(e)) {
                    return e
                }
            })
            
            const index = this.getRandomIntInclusive(1, arrDif.length)

            return arrDif[index - 1]
        }
    }
}