<template>
    <div>
        <template v-for="(fields, id) in data" >
            <v-row :key="`row-${id}`" >
                <v-col
                    v-for="(field, key) in fields"
                    :key="`field-${key}`"
                    :cols="field.col ? field.col.default : 12"
                    :xs="field.col ? field.col.xs : 12"
                    :sm="field.col ? field.col.sm : 12"
                    :md="field.col ? field.col.md : 12"
                    :lg="field.col ? field.col.lg : 12"
                    :xl="field.col ? field.col.xl : 12"
                >
                    <v-text-field
                        v-if="field.field === 'text-field'"
                        v-model="field.value"
                        :label="field.label"
                        @input="handle($event, field.handle, field.name)"
                    ></v-text-field>

                    <v-autocomplete
                        v-else-if="field.field === 'autocomplete'"
                        v-model="field.value"
                        :label="field.label"
                        :items="field.options"
                        :item-text="field.optionText"
                        item-value="id"
                        maxlength="2"
                        @input="handle($event, field.handle, field.name)"
                    ></v-autocomplete>

                    <v-checkbox
                        v-else-if="field.field === 'checkbox'"
                        v-model="field.value"
                        :label="field.label"
                        true-value="s"
                        false-value="n"
                        @input="handle($event, field.handle, field.name)"
                    ></v-checkbox>

                    <v-menu
                        v-else-if="field.field === 'date-picker'"
                        v-model="field.fieldModel"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                        <template v-slot:activator="{on, attrs}" >
                            <v-text-field
                                v-model="field.value"
                                v-bind="attrs"
                                v-on="on"
                                :label="field.label"
                                prepend-icon="mdi-calendar"
                            />
                        </template>
                        <v-date-picker
                            v-model="field.value"
                            scrollable
                            no-title
                            @input="handle($event, field.handle, field.name)"
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
        </template>
    </div>
</template>
<script>
export default {
    name: 'UiCrud',
    data(){
        return {
            // Adicionar regras de inputs aqui
            // Talvez seja uma boa criar uma mixin e salvar
            // todas as regras la.
            rules : {
                required: value => !!value || 'Campo obrigatório.'
            },
            data: [...this.rows]
        }
    },
    props: {
        value: {
            type: Object,
            required: true,
            default(){
                return {}
            }
        },
        rows: {
            type: Array,
            required: true,
            default(){
                return []
            }
        }
    },
    mounted(){
        console.log(this.value)
        this.data.map((row, rowID) => {
            row.map((item, itemID) => {
                if (item.service) {
                    fetch(`${process.env.VUE_APP_API}/generics/description/${item.service}/${item.serviceParams || ''}`, {
                        method: 'GET',
                        headers: new Headers({
                            'Authentication': this.$store.getters.token
                        })
                    })
                        .then(res => res.json())
                        .then(resJSON => {
                            if (resJSON.data) {
                                this.data[rowID][itemID]['options'] = resJSON.response
                            }else{
                                console.log(`Error on ${item.name}`, '=>', resJSON.message)
                            }
                        })
                        .catch(error => {
                            console.error(`Exception on ${item.name}`, '=>', error)
                        })
                }         
            })
        })
    },
    computed: {},
    watch: {
        value(value){
            this.data.map((row, rowID) => {
                row.map((item, itemID) => {
                    if (value[item.name]){
                        this.data[rowID][itemID]['value'] = value[item.name]
                    }
                })
            })
        }
    },
    methods: {
        // eslint-disable-next-line
        handle(event, type, name){
            if (type){
                if (type == 'default') this[type+'Handle'](name)
                else this[type+'Handle'](event, name)
            } 
        },

        defaultHandle(name){
            this.data.map(row => {
                row.map(item => {
                    if (item.name === name) {
                        this.value[name] = item.value
                    }
                })
            })

            this.$emit(`input`, this.value)
        },
    }
}
</script>

<style>

</style>