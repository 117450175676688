import Vue from 'vue'
import Vuex from 'vuex'
import AuthModule from './Auth/index.js'
import FranchiseModule from './Franchise/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        Auth: AuthModule,
        Franchise: FranchiseModule
    }
})