export default {
    methods: {
        formater(value){
            if(value == null || value == undefined || isNaN(value)){
                return 'R$ 0,00'
            }else{
                const formatter = new Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2
                })

                return formatter.format(value)
            }
        }
    }
}