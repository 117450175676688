<template>
    <v-row
        justify="center"
        class="mt-8"
    >
        <v-col
            cols="12"
            md="4"
            xl="4"
            sm="6"
        >
            <v-card>
                <v-card-title
                    class="indigo white--text"
                >
                    <h3>Login</h3>
                </v-card-title>
                <v-card-text class="pt-4" >
                    <v-alert
                        v-model="alert.show"
                        dismissible
                        :color="alert.type"
                        border="left"
                        elevation="2"
                        colored-border
                        transition="scale-transition"
                    >
                        {{alert.text}}
                    </v-alert>
                    <v-form ref="form" >
                        <v-text-field
                            v-model="form.user"
                            label="Login"
                            prepend-inner-icon="mdi-account"
                            type="text"
                            counter
                            maxlength="64"
                            :rules="[
                                form_user_rules.required, form_user_rules.min,
                                    form_user_rules.counter
                            ]"
                        ></v-text-field>
                        <!-- <small v-if="form_telefone_rules.required.invalid" >Esse campo é requerido</small> -->

                        <v-text-field
                            v-model="form.pass"
                            label="Senha"
                            prepend-inner-icon="mdi-key"
                            type="password"
                            maxlength="16"
                            hint="Pelo menos 8 caracteres"
                            :rules="[
                                form_pass_rules.required, form_pass_rules.min
                            ]"
                        ></v-text-field>

                        <!-- <v-checkbox
                            color="indigo"
                            :value="true"
                            label="Manter logado"
                        ></v-checkbox> -->
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="indigo"
                        dark
                        block
                        @click.prevent="login()"
                    >
                        Login
                    </v-btn>
                </v-card-actions>
                <!-- <v-card-actions>
                    <v-btn
                        :to="{name: 'Recover'}"
                        text
                        color="indigo"
                        dark
                        block
                    >
                        Esqueceu a senha?
                    </v-btn>
                </v-card-actions> -->
            </v-card>
        </v-col>
        <v-overlay :value="overlay">
            <v-progress-circular
                size="100"
                color="indigo darken-1"
            >
            </v-progress-circular>
        </v-overlay>
    </v-row>
</template>

<script>
import {mapActions} from 'vuex'
export default {
    name: 'vwLogin',
    data() {
        return {
            overlay: false,
            alert: {
                show: false,
                type: 'indigo',
                text: ''
            },
            form: {
                user: '',
                pass: ''
            },
            form_user_rules: {
                required: value => !!value || 'Usuario é obrigatório.',
                counter: value => value.length <= 64 || 'Usuario inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },
            form_pass_rules: {
                required: value => !!value || 'Senha é obrigatória!',
                min: v => v.length >= 8 || 'Mínimo de 8 caracteres!'
            }
        }
    },
    methods: {
        ...mapActions({
            doLogin: '_LOGIN',
            setFranchise: '_SET_FRANCHISE'
        }),

        login: function(){
            if(this.$refs.form.validate()){
                this.overlay = true
                this.doLogin({
                    user: this.form.user,
                    pass: this.form.pass
                })
                    .then(response => {
                        if(response.data){
                            const user = response.response
                            if (user.super !== 'S') {
                                this.setFranchise({
                                    id: user.idEmpresa,
                                    franchise: user.empresa,
                                    date: user.dataAtual
                                })
                            }

                            this.$router.push({
                                name: 'Home'
                            })
                        }else{
                            this.alert = {
                                text: response.message,
                                show: true,
                                type: 'red darken-1'
                            }
                        }
                    })
                    .catch((error) => {
                        this.alert = {
                            text: error.message,
                            show: true,
                            type: 'red darken-1'
                        }
                    })
                    .finally(() => {
                        this.overlay = false
                    })
            }
        }
    }
}
</script>
