<template>
    <v-container
        fluid
        class="franchise-container"
    >
        <v-card
            class="franchise-container-card"
        >
            <v-card-title>
                Franquias - Mapa e Controle financeiro
            </v-card-title>

            <v-card-text
                class="franchise-container-card-text"
            >
                <v-row
                    style="height: 100%;"
                >
                    <v-col
                        cols="8"
                        class="scroller"
                    >
                        <v-row>
                            <v-col 
                                cols="12" 
                                md="6"
                                class="franchise-card"
                                v-for="company in companies" 
                                :key="company.id" 
                            >
                                <v-card class="py-1 px-4 text-left" @click="franchiseEvent(company)" >
                                    <v-card-title>
                                        {{company.empresaNome || 'Sem nome cadastrado'}}         
                                    </v-card-title>
                                    <v-card-text>
                                        <ul class="list-unstyled" >
                                            <li><i class="fas fa-user-tie"></i> {{company.donoNome || 'Sem responsável'}}</li>
                                            <li><i class="far fa-envelope"></i> {{(company.empresaEmail || company.donoEmail) || ' - '}}</li>
                                            <li><i class="fas fa-phone-volume"></i> {{(company.empresaTelefone || company.donoTelefone) || ' - '}}</li>
                                            <li><i class="fas fa-cash-register"></i> {{formater(company.faturamento) || ' - '}}</li>
                                            <li><i class="fas fa-wallet"></i> {{formater(Math.round((company.faturamento * 5) / 100 ))}}</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="4"
                    >
                        Mapa
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import numeric from "@/mixins/numeric"
    import helper from "@/mixins/helper"
    export default {
        name: 'franchaseSelector',
        mixins: [numeric, helper],
        data(){
            return {
                layout: {
                    showSidebar: false,
                    overlay: false,
                    search: null
                },

                companies: [],
                selected: {
                    name: null,
                    address: null,
                    id: null
                }
            }
        },
        methods: {
            franchiseEvent(franchise){
                this.$store.dispatch('_SET_FRANCHISE', {
                    franchise: franchise.empresaNome,
                    id: franchise.id,
                    date: franchise.dataAtual
                })

                this.$root.$emit('reload-all-franchise-graphs', franchise.id)
                this.$root.$emit('franchise-close-sidebar')
            }
        },
        async mounted() {
            const response = await this.getData(process.env.VUE_APP_API + '/info/companies', {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })                
            })

            if (response.data)
                this.companies = response.response
            else
                this.alert = {
                    text: response.message,
                    show: true,
                    type: 'red darken-1'
                }
        },
    }
</script>
<style scoped>
    .scroller{
        /* max-height: 455px; */
        overflow-y: scroll;
        height: 100%;
    }
    .scroller::-webkit-scrollbar,
    .scroller::-moz-scrollbar{
        width: 4px;
        height: 6px;
    }
    .franchise-container{
        height: 100%;
    }

    .franchise-container-card{
        height: 100%; 
        display: flex; 
        flex-direction: column;
    }

    .franchise-container-card-text{
        height: 100%;
    }

    .franchise-card{
        height: 225px;
    }

    .list-unstyled{
        padding: 0px !important;
        margin: 0px !important;

        list-style-type: none;
    }

        .list-unstyled li{
            list-style-type: none;
        }

        .list-unstyled i {
            display: inline-block;
            width: 35px;
            font-size: 20px;
            text-align: center;
        }
</style>