<template>
    <v-row class="mx-2 my-2" >
        <v-col
            cols="12"
        >
            <v-card>
                <v-card-title class="justify-space-between mx-8" >
                    <h3>
                        Caixa detalhado por dia
                    </h3>

                    <div class="mx-2" >
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="cashRegisterDate"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            max-width="280px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="cashRegisterDate"
                                    label="Alterar data do caixa"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="cashRegisterDate"
                                no-title
                                scrollable
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="getCashRegister"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </div>
                </v-card-title>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
            <v-card>
                <v-card-title>
                    {{ `Caixa - ${data.values.datacx}` }}
                </v-card-title>

                <v-card-text>
                    <h5>Sangria e suprimentos</h5>
                    <p>
                        <span>
                            ( + ) Abertura do caixa
                        </span>
                        <span>
                                {{formater(data.values.saldoAnterior)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( + ) Entradas no caixa
                        </span> 
                        <span>
                            {{formater(data.values.suplemento)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( - ) Retiradas no caixa 
                        </span>
                        <span>
                            {{formater(data.values.sangrias)}}
                        </span>
                    </p>

                    <hr class="border">

                <h5>Recebimentos</h5>
                    <p>
                        <span>
                            ( + ) Dinheiro 
                        </span>
                        <span>
                            {{formater(data.values.totRecebimentos)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( + ) Cheque 
                        </span>
                        <span>
                            {{formater(data.values.totRecebChq)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( + ) Cartão 
                        </span>
                        <span>
                            {{formater(data.values.totRecebCar)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( + ) Banco 
                        </span>
                        <span>
                            {{formater(data.values.totRecebBan)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( + ) Vale crédito 
                        </span>
                        <span>
                            {{formater(data.values.totRecebCre)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <strong>Total: </strong>
                        {{formater(
                            parseFloat(data.values.totRecebimentos) + 
                            parseFloat(data.values.totRecebChq) + 
                            parseFloat(data.values.totRecebCar) + 
                            parseFloat(data.values.totRecebBan) +
                            parseFloat(data.values.totRecebCre)
                        )}}
                    </p>

                    <hr class="border">

                <h5>Pagamentos</h5>
                    <p>
                        <span>
                            ( - ) Dinheiro 
                        </span>
                        <span>
                            {{formater(data.values.totPagamentos)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( - ) Cheque 
                        </span>
                        <span>
                            {{formater(data.values.totPagamChq)}} 
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( - ) Cartão 
                        </span>
                        <span>
                            {{formater(data.values.totPagamCar)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( - ) Banco 
                        </span>
                        <span>
                            {{formater(data.values.totPagamBan)}}
                        </span>
                    </p>
                    <hr class="border-dotted" >
                    <p>
                        <strong>Total: </strong>
                        {{formater(
                            parseFloat(data.values.totPagamentos) + 
                            parseFloat(data.values.totPagamChq) + 
                            parseFloat(data.values.totPagamCar) + 
                            parseFloat(data.values.totPagamBan)
                        )}}
                    </p>

                    <hr class="border">

                <h3>
                    <span>
                        Saldo Final:
                    </span>
                    {{formater(data.values.saldoAtual)}}
                </h3>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
            <v-card>
                <v-card-title>
                    Faturamento
                </v-card-title>

                <v-card-text>
                    <h5>
                        Vendas e O.S.
                    </h5>
                    <p>
                        <span>
                            ( + ) Dinheiro  
                        </span>
                        <span>
                            {{formater(data.values.totaisAvista)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( + ) Cheque a vista  
                        </span>
                        <span>
                            {{formater(data.values.venChqbom)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( + ) Cartão de débito  
                        </span>
                        <span>
                            {{formater(data.values.venCartaoD)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( + ) Cartão de crédito 
                        </span>
                        <span>
                            {{formater(data.values.venCartaoC)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( + ) A prazo 
                        </span>
                        <span>
                            {{formater(data.values.venAprazo)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( + ) Boleto 
                        </span>
                        <span>
                            {{formater(data.values.venBoleto)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( + ) Cheques pré-datado 
                        </span>
                        <span>
                            {{formater(data.values.venChqpre)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( + ) Convênios 
                        </span>
                        <span>
                            {{formater(data.values.venConvenio)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( + ) Outros 
                        </span>
                        <span>
                            {{formater(data.values.venOutras)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( + ) Garantia 
                        </span>
                        <span>
                            {{formater(data.values.venGarantia)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            ( + ) Vale-Crédito 
                        </span>
                        <span>
                            {{formater(data.values.venValecredito)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <hr class="border-dotted" > 
                    <p>
                        <strong>Total Geral</strong> 
                        {{formater(
                            parseFloat(data.values.totaisAvista) +
                            parseFloat(data.values.venChqbom) +
                            parseFloat(data.values.venCartaoD) +
                            parseFloat(data.values.venCartaoC) +
                            parseFloat(data.values.venAprazo) +
                            parseFloat(data.values.venBoleto) +
                            parseFloat(data.values.venChqpre) +
                            parseFloat(data.values.venConvenio) +
                            parseFloat(data.values.venOutras) +
                            parseFloat(data.values.venGarantia) +
                            parseFloat(data.values.venValecredito)
                        )}}
                    </p>

                <hr class="border">

                <h5>Total Geral</h5>
                    <p>
                        <span>
                            Total a vista 
                        </span>
                        <span>
                            {{formater(data.values.totaisOsAvista)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            Total de cartões 
                        </span>
                        <span>
                            {{formater((parseFloat(data.values.serCartaoC) + parseFloat(data.values.serCartaoD)))}}
                        </span>
                    </p> 
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            Total a prazo 
                        </span>
                        <span>
                            {{formater(data.values.totaisAprazo)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            Total (Garantia + Crédito) 
                        </span>
                        <span>
                            {{formater((data.values.totGarantia + data.values.totValecredito))}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            Total de cancelamentos 
                        </span>
                        <span>
                            {{formater(data.values.totalCancelados)}}
                        </span>
                    </p>
                    <hr class="border-dotted" > 
                    <p>
                        <span>
                            Total de trocas/devoluções 
                        </span>
                        <span>
                            {{formater(data.values.totalDevolucoes)}}
                        </span>
                    </p>
                </v-card-text>
            </v-card>
        </v-col>

        <v-overlay 
            :value="overlay"
            absolute
        >
            <v-progress-circular
                size="100"
                color="indigo darken-1"
            >
            </v-progress-circular>
        </v-overlay>
    </v-row>
</template>

<script>
import helper from "@/mixins/helper"
import numeric from "@/mixins/numeric"
import { mapGetters } from 'vuex'
export default {
    name: 'vwCashRegister',

    mixins: [helper, numeric],

    data() {
        return {
            overlay: false,
            cashRegisterDate: this.$moment().format('YYYY-MM-DD'),
            menu: false,
            data: {
                values: {

                }
            }
        }
    },

    methods: {
        async getCashRegister() {
            this.overlay = true
            this.menu = false
            const date = this.$moment(this.cashRegisterDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
            const response = await this.getData(`${process.env.VUE_APP_API}/graphs/generics/cashregister/${date}`, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.userToken,
                    'Franchise': this.franchiseID,
                    'FranchiseDate': null
                })
            })

            if (response.data) {
                this.$set(this.data, 'values', response.response)
            }else {
                this.$root.$on('show-exception-alert', response.message)
            }

            this.overlay = false
        }
    },

    computed: {
        ...mapGetters({
            userToken: 'token',
            franchiseID: 'franchiseId'
        })
    },

    mounted() {
        this.getCashRegister()

        this.$root.$on('reload-all-franchise-graphs', () => {
            this.getCashRegister()
        })
    }
}
</script>
<style scoped>
    hr.border-dotted{
        border-style: dotted;
        margin: 0px !important;
        padding: 0px !important;
    }
</style>